import { container } from './container';
import { TOKENS } from './tokens';

// Core
import { Podocore } from './Podocore';

// Modules
import { AclModule } from './modules/acl/acl.module';
import { AuthModule } from './modules/auth/auth.module';
import { BusModule } from '@/plugins/podocore/modules/bus/bus.module';
import { ProfileModule } from '@/plugins/podocore/modules/profile/profile.module';
import { DoctorModule } from '@/plugins/podocore/modules/doctor/doctor.module';
import { MaintenanceModule } from './modules/maintenance/maintenance.module';
import { RequestModule } from './modules/request/request.module';
import { WebsocketModule } from './modules/websocket/websocket.module';
import { WorkspacesModule } from './modules/workspaces/workspaces.module';
import { VersionModule } from './modules/version/version.module';
import { SaasModule } from './modules/saas/saas.module';

// Modules aggregator
import { Modules } from './modules';

// Repositories
import { MaintenanceRepository } from './repositories/maintenance.repository';
import { DoctorRepository } from './repositories/doctor.repository';
import { WorkspacesRepository } from './repositories/workspaces.repository';
import { SaasRepository } from './repositories/saas.repository';

// Repositories aggregator
import { Repositories } from './repositories';

// -------------------------------------------------------------------------------------------------

// Podocore
container.bind<Podocore>(TOKENS.Podocore).to(Podocore).inSingletonScope();

// Modules
container.bind<AclModule>(TOKENS.AclModule).to(AclModule).inSingletonScope();
container.bind<AuthModule>(TOKENS.AuthModule).to(AuthModule).inSingletonScope();
container.bind<BusModule>(TOKENS.BusModule).to(BusModule).inSingletonScope();
container.bind<ProfileModule>(TOKENS.ProfileModule).to(ProfileModule).inSingletonScope();
container.bind<DoctorModule>(TOKENS.DoctorModule).to(DoctorModule).inSingletonScope();
container.bind<MaintenanceModule>(TOKENS.MaintenanceModule).to(MaintenanceModule).inSingletonScope();
container.bind<RequestModule>(TOKENS.RequestModule).to(RequestModule).inSingletonScope();
container.bind<WebsocketModule>(TOKENS.WebsocketModule).to(WebsocketModule).inSingletonScope();
container.bind<WorkspacesModule>(TOKENS.WorkspacesModules).to(WorkspacesModule).inSingletonScope();
container.bind<VersionModule>(TOKENS.VersionModule).to(VersionModule).inSingletonScope();
container.bind<SaasModule>(TOKENS.SaasModule).to(SaasModule).inSingletonScope();

// Modules aggregator
container.bind<Modules>(TOKENS.Modules).to(Modules).inSingletonScope();

// Repositories
container
  .bind<MaintenanceRepository>(TOKENS.MaintenanceRepository)
  .to(MaintenanceRepository)
  .inSingletonScope();
container.bind<DoctorRepository>(TOKENS.DoctorRepository).to(DoctorRepository).inSingletonScope();
container.bind<WorkspacesRepository>(TOKENS.WorkspacesRepository).to(WorkspacesRepository).inSingletonScope();
container.bind<SaasRepository>(TOKENS.SaasRepository).to(SaasRepository).inSingletonScope();

// Repositories aggregator
container.bind<Repositories>(TOKENS.Repositories).to(Repositories).inSingletonScope();
