// Import vendors ----------------------------------------------------------------------------------
import { actions, assign, createMachine, sendParent } from 'xstate';
import { Auth } from '@aws-amplify/auth';
import { toDataURL } from 'qrcode';
import { switchMap } from 'rxjs/operators';
// Import IoC --------------------------------------------------------------------------------------
import { container, Podocore, TOKENS } from '@/plugins/podocore';
// -------------------------------------------------------------------------------------------------

/**
 * MFA machine
 */
export const mfaMachine = createMachine(
  {
    id: 'mfa',
    context: {
      associationSoftwareToken: undefined,
      associationSoftwareTokenQrCode: undefined,
      associatingSoftwareTokenError: undefined,
      associatingSoftwareTokenQrCodeError: undefined,
      verifyingSoftwareTokenError: undefined,
      deactivationError: undefined
    },
    initial: 'idle',
    states: {
      idle: {
        entry: assign({
          associationSoftwareToken(_) {
            return undefined;
          },
          associationSoftwareTokenQrCode(_) {
            return undefined;
          }
        }),
        on: {
          ASSOCIATE_SOFTWARE_TOKEN: '#mfa.associatingSoftwareToken',
          DEACTIVATE: '#mfa.deactivating'
        }
      },
      associatingSoftwareToken: {
        initial: 'generateToken',
        states: {
          generateToken: {
            initial: 'processing',
            states: {
              processing: {
                invoke: {
                  src: 'associateSoftwareToken',
                  onDone: {
                    actions: assign({
                      associationSoftwareToken(_, { data }) {
                        return data;
                      }
                    }),
                    target: '#mfa.associatingSoftwareToken.generateTokenQrCode'
                  },
                  onError: {
                    actions: assign({
                      associatingSoftwareTokenError(_, { data }) {
                        return data;
                      }
                    }),
                    target: '#mfa.associatingSoftwareToken.generateToken.failure'
                  }
                }
              },
              failure: {
                exit: assign({
                  associatingSoftwareTokenError(_) {
                    return undefined;
                  }
                }),
                on: {
                  ASSOCIATE_SOFTWARE_TOKEN: '#mfa.associatingSoftwareToken',
                  RESET: '#mfa.idle'
                }
              }
            }
          },
          generateTokenQrCode: {
            initial: 'processing',
            states: {
              processing: {
                invoke: {
                  src: 'generateQrCode',
                  onDone: {
                    actions: assign({
                      associationSoftwareTokenQrCode(_, { data }) {
                        return data;
                      }
                    }),
                    target: '#mfa.associatingSoftwareToken.generateTokenQrCode.success'
                  },
                  onError: {
                    actions: assign({
                      associatingSoftwareTokenQrCodeError(_, { data }) {
                        return data;
                      }
                    }),
                    target: '#mfa.associatingSoftwareToken.generateTokenQrCode.failure'
                  }
                }
              },
              success: {
                on: {
                  RESET: '#mfa.idle',
                  VERIFYING_SOFTWARE_TOKEN: '#mfa.verifyingSoftwareToken'
                }
              },
              failure: {
                exit: assign({
                  associatingSoftwareTokenQrCodeError(_) {
                    return undefined;
                  }
                }),
                on: {
                  ASSOCIATE_SOFTWARE_TOKEN: '#mfa.associatingSoftwareToken',
                  RESET: '#mfa.idle'
                }
              }
            }
          }
        }
      },
      verifyingSoftwareToken: {
        initial: 'processing',
        states: {
          processing: {
            invoke: {
              src: 'verifySoftwareToken',
              onDone: '#mfa.verifyingSoftwareToken.success',
              onError: {
                actions: assign({
                  verifyingSoftwareTokenError(_, { data }) {
                    return data;
                  }
                }),
                target: '#mfa.verifyingSoftwareToken.failure'
              }
            }
          },
          success: {
            entry: actions.pure(() => {
              return sendParent({
                type: 'CHECK'
              });
            }),
            on: {
              RESET: '#mfa.idle'
            }
          },
          failure: {
            exit: assign({
              verifyingSoftwareTokenError(_) {
                return undefined;
              }
            }),
            on: {
              VERIFYING_SOFTWARE_TOKEN: '#mfa.verifyingSoftwareToken',
              RESET: '#mfa.idle'
            }
          }
        }
      },
      deactivating: {
        initial: 'processing',
        states: {
          processing: {
            invoke: {
              src: 'deactivating',
              onDone: '#mfa.deactivating.success',
              onError: {
                actions: assign({
                  deactivationError(_, { data }) {
                    return data;
                  }
                }),
                target: '#mfa.deactivating.failure'
              }
            }
          },
          success: {
            entry: actions.pure(() => {
              return sendParent({
                type: 'CHECK'
              });
            }),
            after: {
              3000: '#mfa.idle'
            },
            on: {
              RESET: '#mfa.idle'
            }
          },
          failure: {
            exit: assign({
              deactivationError(_) {
                return undefined;
              }
            }),
            on: {
              VERIFYING_SOFTWARE_TOKEN: '#mfa.verifyingSoftwareToken',
              DEACTIVATE: '#mfa.deactivating',
              RESET: '#mfa.idle'
            }
          }
        }
      }
    }
  },
  {
    services: {
      /**
       * Associate software token
       */
      async associateSoftwareToken() {
        // Retrieve the current user
        return container
          .get<Podocore>(TOKENS.Podocore)
          .getModule('auth')
          .getUser()
          .pipe(
            switchMap(async (user) => {
              // Retrieve a software token
              return Auth.setupTOTP(user);
            })
          )
          .toPromise();
      },
      /**
       * Generate token
       */
      async generateQrCode(context: any) {
        // Retrieve the current user
        return container
          .get<Podocore>(TOKENS.Podocore)
          .getModule('auth')
          .getUser()
          .pipe(
            switchMap(async (user) => {
              // Generate the QR code
              return toDataURL(
                `otpauth://totp/Podosmart:${user.attributes.email}?secret=${context.associationSoftwareToken}&issuer=Amazon%20Cognito`,
                { width: 400 }
              );
            })
          )
          .toPromise();
      },
      /**
       * Verify software token
       */
      async verifySoftwareToken(_, { data }: any) {
        // Retrieve the current user
        return container
          .get<Podocore>(TOKENS.Podocore)
          .getModule('auth')
          .getUser()
          .pipe(
            switchMap(async (user) => {
              // Verify software token...
              await Auth.verifyTotpToken(user, data.code);
              // Return user
              return user;
            }),
            switchMap(async (user) => {
              // Set preferred MFA method
              return Auth.setPreferredMFA(user, 'TOTP');
            })
          )
          .toPromise();
      },
      /**
       * Deactivating
       */
      async deactivating() {
        // Retrieve the current user
        return container
          .get<Podocore>(TOKENS.Podocore)
          .getModule('auth')
          .getUser()
          .pipe(
            switchMap(async (user) => {
              // Set preferred MFA method
              return Auth.setPreferredMFA(user, 'NOMFA');
            })
          )
          .toPromise();
      }
    }
  }
);
