









































// Import vendors ----------------------------------------------------------------------------------
import { computed, Data, defineComponent, ref, watch } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
import { startsWith } from 'lodash';
// Import utils ------------------------------------------------------------------------------------
import { useDialog } from '@/utils/dialog.utils';
// Import types ------------------------------------------------------------------------------------
import type { PropType } from '@vue/composition-api';
import type { DialogId } from '@/plugins/podocore/modules/bus/bus.module';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'CompositeDialog',
  props: {
    id: {
      type: String as PropType<DialogId>,
      required: true
    },
    filterMeta: {
      type: Object,
      default: null
    },
    closable: {
      type: Boolean,
      default: true
    },
    tile: {
      type: Boolean,
      default: false
    }
  },
  setup(properties, { slots, attrs }) {
    const { dialog, displayContent, open, close } = useDialog();

    const bus = usePodocoreModule('bus');

    const meta = ref<any>(null!);

    const cardAttributes = computed(() => {
      return Object.entries(attrs).reduce((accumulator, [key, value]) => {
        if (startsWith(key, 'card')) {
          accumulator[key.replace(/^card\./, '')] = value;
        }
        return accumulator;
      }, {} as Data);
    });

    function skipIfMetaIsFiltered(meta: Record<string, any>) {
      if (!properties.filterMeta) return;

      return (
        Object.entries(properties.filterMeta).filter(([key, value]) => {
          return meta[key] !== value;
        }).length > 0
      );
    }

    bus.useEventSubscriber(bus.events.openDialog, (event) => {
      if (event.payload.id === properties.id) {
        if (event.payload.meta && skipIfMetaIsFiltered(event.payload.meta)) return;
        meta.value = event.payload.meta;
        open(properties.id);
      }
    });

    bus.useEventSubscriber(bus.events.closeDialog, (event) => {
      if (event.payload.id === properties.id) {
        if (event.payload.meta && skipIfMetaIsFiltered(event.payload.meta)) return;
        close(properties.id);
      }
    });

    const hasTitle = computed(() => {
      return Boolean(slots.title);
    });

    const hasCustomTitle = computed(() => {
      return Boolean(slots.customtitle);
    });

    const hasActions = computed(() => {
      return Boolean(slots.actions);
    });

    const hasCustomActions = computed(() => {
      return Boolean(slots.customactions);
    });

    watch(dialog, () => {
      setTimeout(() => {
        if (!dialog.value) meta.value = null;
      }, 200);
    });

    return {
      dialog,
      displayContent,
      hasTitle,
      hasCustomTitle,
      hasActions,
      hasCustomActions,
      meta,
      close,
      open,
      cardAttributes
    };
  }
});
