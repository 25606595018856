// Import vendors ----------------------------------------------------------------------------------
import { injectable, inject } from 'inversify';
// Import IoC --------------------------------------------------------------------------------------
import { TOKENS } from '../tokens';
// Import repositories -----------------------------------------------------------------------------
import { MaintenanceRepository } from './maintenance.repository';
import { DoctorRepository } from './doctor.repository';
import { WorkspacesRepository } from './workspaces.repository';
import { SaasRepository } from './saas.repository';
// -------------------------------------------------------------------------------------------------

/**
 * # Repositories
 * Aggregate all repositories
 */
@injectable()
export class Repositories {
  constructor(
    @inject(TOKENS.MaintenanceRepository) public readonly maintenance: MaintenanceRepository,
    @inject(TOKENS.DoctorRepository) public readonly doctor: DoctorRepository,
    @inject(TOKENS.WorkspacesRepository) public readonly workspaces: WorkspacesRepository,
    @inject(TOKENS.SaasRepository) public readonly saas: SaasRepository
  ) {}
}
