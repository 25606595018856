// Import vendors ----------------------------------------------------------------------------------
import { injectable, inject } from 'inversify';
import { actions, assign, sendParent } from 'xstate';
import { map, take } from 'rxjs/operators';
import { merge, omit, pick } from 'lodash';
import { datadogRum } from '@datadog/browser-rum';
// Import factories --------------------------------------------------------------------------------
import { ModuleWithStateFactory } from '../../factories/ModuleWithState.factory';
// Import IoC --------------------------------------------------------------------------------------
import { TOKENS } from '../../tokens';
// Import helpers ----------------------------------------------------------------------------------
import { createModuleStateMachine } from '../../helpers/modules.helpers';
// Import repositories -----------------------------------------------------------------------------
import { WorkspaceEntity, WorkspacesRepository } from '../../repositories/workspaces.repository';
// -------------------------------------------------------------------------------------------------

@injectable()
export class WorkspacesModule extends ModuleWithStateFactory {
  private _chargebee: any;

  constructor(
    @inject(TOKENS.WorkspacesRepository) private readonly _workspacesRepository: WorkspacesRepository
  ) {
    super();

    const unsubscribeSet = new Set();

    this._machine = createModuleStateMachine(
      this._name,
      {
        context: WorkspacesModule.getDefaultContext(),
        initial: 'fetching',
        states: {
          fetchingSaas: {
            invoke: {
              src: 'fetchSaasMeta',
              onDone: {
                actions: [
                  assign({
                    saasSite: (_, { data }) => data.site
                  }),
                  function (_, { data }) {
                    console.log(`📊 Saas meta fetched : ${data.site}`);
                  }
                ],
                target: 'createInstance'
              },
              onError: {
                actions: assign({
                  error(_, { data }) {
                    return data;
                  }
                }),
                target: 'failure'
              }
            }
          },

          createInstance: {
            invoke: {
              src: 'createInstance',
              onDone: 'success',
              onError: {
                actions: assign({
                  error(_, { data }) {
                    return data;
                  }
                }),
                target: 'failure'
              }
            }
          },

          fetching: {
            invoke: {
              src: 'fetchWorkspaces',
              onDone: {
                actions: [
                  assign({
                    workspaces: (_, { data }) => data
                  }),
                  function () {
                    console.log('🏢 Workspaces updated');
                  }
                ],
                target: 'configuring'
              },
              onError: {
                actions: assign({
                  error(_, { data }) {
                    return data;
                  }
                }),
                target: 'failure'
              }
            }
          },
          configuring: {
            always: [
              {
                cond: () => {
                  const saas = sessionStorage.getItem('saas');
                  if (saas) return !!JSON.parse(saas).workspaceCuid;
                  return false;
                },
                actions: assign({
                  __autoCurrent: () => {
                    return JSON.parse(sessionStorage.getItem('saas') as any).workspaceCuid;
                  }
                }),
                target: 'selecting'
              },
              {
                cond: () => {
                  return !!new URL(document.location.href).searchParams.get('workspaceCuid');
                },
                actions: assign({
                  __autoCurrent: () => {
                    return new URL(document.location.href).searchParams.get('workspaceCuid');
                  }
                }),
                target: 'selecting'
              },
              {
                cond: () => !!localStorage.getItem('workspace')?.length,
                actions: assign({
                  __autoCurrent: () => {
                    return localStorage.getItem('workspace');
                  }
                }),
                target: 'selecting'
              },
              {
                target: 'selecting'
              }
            ]
          },
          selecting: {
            invoke: {
              src: 'selectWorkspace',
              onDone: {
                actions: [
                  assign({
                    __autoCurrent: () => undefined,
                    current: (_, { data }) => {
                      localStorage.setItem('workspace', data.cuid);
                      return data;
                    }
                  }),
                  function () {
                    console.log('🏢 Workspace selected');
                  }
                ],
                target: 'fetchingSaas'
              },
              onError: {
                actions: assign({
                  error(_, { data }) {
                    return data;
                  }
                }),
                target: 'failure'
              }
            }
          },
          create: {
            always: {
              actions: (_, { data }) => localStorage.setItem('workspace', data.cuid),
              target: 'fetching'
            }
          },
          patchingCurrent: {
            initial: 'processing',
            states: {
              processing: {
                invoke: {
                  src: 'patchWorkspace',
                  onDone: {
                    actions: [
                      assign({
                        current: (context, { data }) => {
                          localStorage.setItem('workspace', data.cuid);
                          return {
                            ...omit(data, ['members', 'invitedMembers']),
                            ...pick(
                              context.workspaces.docs.find(({ cuid }: any) => data.cuid === cuid),
                              ['members', 'invitedMembers']
                            )
                          };
                        },
                        workspaces: (context, { data }) =>
                          merge({}, context.workspaces, {
                            docs: context.workspaces.docs.map((workspace: any) => {
                              if (workspace.cuid === data.cuid) {
                                return {
                                  ...omit(data, ['members', 'invitedMembers']),
                                  ...pick(workspace, ['members', 'invitedMembers'])
                                };
                              } else return workspace;
                            })
                          })
                      }),
                      function () {
                        console.log('🏢 Workspace patched');
                      }
                    ],
                    target: 'success'
                  },
                  onError: {
                    actions: assign({
                      patchingError(_, { data }) {
                        return data;
                      }
                    }),
                    target: 'failure'
                  }
                }
              },
              success: {
                on: {
                  SELECT: [
                    {
                      cond: (context, { data }) => context.current?.cuid !== data.workspaceCuid,
                      target: '#module.selecting'
                    }
                  ],
                  CREATE: '#module.create',
                  PATCH_CURRENT: 'processing'
                },
                after: {
                  3000: '#module.success'
                }
              },
              failure: {
                exit: assign({
                  patchingError(_) {
                    return undefined;
                  }
                }),
                on: {
                  SELECT: [
                    {
                      cond: (context, { data }) => context.current?.cuid !== data.workspaceCuid,
                      target: '#module.selecting'
                    }
                  ],
                  CREATE: '#module.create',
                  PATCH_CURRENT: 'processing'
                }
              }
            }
          },
          success: {
            entry: [
              actions.pure(() => sendParent({ type: 'FETCHED' })),
              (context) => {
                const bus = this._core.getModule('bus');
                bus.publish(bus.events.workspaceChanged());

                datadogRum.setUser({
                  workspaceCuid: context.current.cuid
                });

                unsubscribeSet.add(
                  bus.subscribe(bus.events.workspaceCreated, ({ payload }) => {
                    this.service.send({
                      type: 'CREATE',
                      data: payload.workspace
                    });
                  })
                );

                // Listen patient deletion
                unsubscribeSet.add(
                  bus.subscribe(bus.events.patientDeleted, () => {
                    this.service.send({
                      type: 'FETCH'
                    });
                  })
                );
              }
            ],
            exit: () => {
              unsubscribeSet.forEach((unsuscribe) => unsuscribe);
            },
            on: {
              SELECT: [
                {
                  cond: (context, { data }) => context.current?.cuid !== data.workspaceCuid,
                  target: 'selecting'
                }
              ],
              CREATE: 'create',
              PATCH_CURRENT: 'patchingCurrent',
              FETCH: 'fetching'
            }
          },
          failure: {
            entry: actions.pure((context) => {
              return sendParent({ type: 'FATAL', data: context.error });
            }),
            exit: assign({
              error(_) {
                return undefined;
              }
            })
          }
        }
      },
      {
        services: {
          createInstance: this.createInstance.bind(this),
          fetchSaasMeta: (context) =>
            this._workspacesRepository
              .fetchSaasMeta({ params: { workspaceCuid: context.current.cuid } })
              .pipe(map((response) => response.data))
              .toPromise(),
          fetchWorkspaces: () =>
            this._workspacesRepository
              .fetchWorkspaces()
              .pipe(map((response) => response.data))
              .toPromise(),
          patchWorkspace: (context, { data }) =>
            this._workspacesRepository
              .patchWorkspace(context.current.cuid, data)
              .pipe(map((response) => response.data))
              .toPromise(),
          selectWorkspace: this.selectWorkspace.bind(this)
        }
      }
    );
  }

  private static getDefaultContext() {
    return {
      workspaces: null,
      current: undefined,
      saasSite: undefined,
      /** Used for initialization only */
      __autoCurrent: undefined,

      error: undefined,
      patchingError: undefined
    };
  }

  async createInstance(): Promise<void> {
    this._chargebee = (window as any).Chargebee.init({ site: this.service.state.context.saasSite });
  }

  async selectWorkspace(context: any, event: any): Promise<any> {
    const cuid = context.__autoCurrent || event.data.workspaceCuid;

    if (cuid) {
      const wk = context.workspaces.docs.find((workspace: WorkspaceEntity) => workspace.cuid === cuid);
      if (wk) return wk;
    }

    const doctorCuid = await this._core
      .getModule('doctor')
      .state$.pipe(
        map((value) => value.context.doctor.cuid),
        take(1)
      )
      .toPromise();

    // If not cuid, select default workspace
    return context.workspaces.docs.find(
      (workspace: WorkspaceEntity) => workspace.defaultForDoctorCuid === doctorCuid
    );
  }

  public getCurrentWorkspace(): any | undefined {
    return this.service.state.context.current;
  }

  public get chargebee(): any {
    return this._chargebee;
  }
}
