import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import GlobalIcon from '@/components/globals/GlobalIcon.vue';
import i18n from './i18n';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    t: (key, ...parameters) => i18n.t(key.substr(1), parameters) as string
  },
  theme: {
    dark: false,
    default: 'default',
    disable: false,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#003DD1',
        secondary: '#020C27',
        accent: '#FF804C',
        error: '#FF4242',
        info: '#0137bc',
        success: '#71CC26',
        warning: '#FFD632',
        background: '#FAFAFA',
        // Greys
        grey1: '#eeeeee',
        grey2: '#e0e0e0',
        grey3: '#bfbfbf',
        grey4: '#7E8391',
        // Blues
        blue1: '#F2F6F9',
        blue2: '#93B4D2',
        // Charts
        chart1: '#ff8300',
        chart2: '#2962FD',
        chart3: '#6c5ce7',
        chart4: '#00b894',
        chart5: '#d42f68',
        chart6: '#0093dd',
        // Analysis
        walking: '#003DD1',
        running: '#71CC26',
        rehab: '#C874D9'
      } /* ,
      dark: {
        primary: '#004C92',
        secondary: '#F4F4F4',
        accent: '#3F91DF',
        error: '#ff6356',
        info: '#63A5E5',
        success: '#4BC180',
        warning: '#ffad46',
        // Greys
        grey4: '#eeeeee',
        grey2: '#e0e0e0',
        grey3: '#bfbfbf',
        // Charts
        chart1: '#0984e3',
        chart2: '#ff7675',
        chart3: '#00b894',
        chart4: '#f1b54f',
        chart5: '#636e72',
        chart6: '#6c5ce7'
      } */
    }
  },
  icons: {
    iconfont: 'faSvg',
    values: {
      // complete: {},
      // cancel: {},
      // close: {},
      // delete: {}, // delete (e.g. v-chip close)
      clear: {
        component: GlobalIcon,
        props: {
          library: 'light',
          icon: 'times'
        }
      },
      // success: {},
      // info: {},
      warning: {
        component: GlobalIcon,
        props: {
          library: 'solid',
          icon: 'exclamation-triangle',
          size: 24
        }
      },
      // error: {},
      prev: {
        component: GlobalIcon,
        props: {
          library: 'light',
          icon: 'angle-left',
          size: 24
        }
      },
      next: {
        component: GlobalIcon,
        props: {
          library: 'light',
          icon: 'angle-right',
          size: 24
        }
      },
      checkboxOn: {
        component: GlobalIcon,
        props: {
          library: 'light',
          icon: 'check-square',
          size: 24
        }
      },
      checkboxOff: {
        component: GlobalIcon,
        props: {
          library: 'light',
          icon: 'square',
          size: 24
        }
      },
      // checkboxIndeterminate: {},
      // delimiter: {}, // for carousel
      sort: {
        component: GlobalIcon,
        props: {
          library: 'light',
          icon: 'angle-down',
          size: 24
        }
      },
      expand: {
        component: GlobalIcon,
        props: {
          library: 'light',
          icon: 'chevron-down'
        }
      },
      // menu: {},
      // subgroup: {},
      dropdown: {
        component: GlobalIcon,
        props: {
          library: 'light',
          icon: 'chevron-down'
        }
      },
      darkdropdown: {
        component: GlobalIcon,
        props: {
          library: 'regular',
          icon: 'chevron-down',
          color: 'white'
        }
      },
      // radioOn: {},
      // radioOff: {},
      // edit: {},
      // ratingEmpty: {},
      // ratingFull: {},
      // ratingHalf: {},
      // loading: {},
      // first: {},
      // last: {},
      // unfold: {},
      // file: {},
      search: {
        component: GlobalIcon,
        props: {
          library: 'regular',
          icon: 'search',
          color: 'gray',
          size: '15'
        }
      },
      filter: {
        component: GlobalIcon,
        props: {
          library: 'regular',
          icon: 'filter',
          color: 'gray',
          size: '15'
        }
      }
    }
  }
});
