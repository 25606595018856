// Import vendors ----------------------------------------------------------------------------------
import { computed } from '@vue/composition-api';
// Import type -------------------------------------------------------------------------------------
import type { Ref } from '@vue/composition-api';
// Export / declare types --------------------------------------------------------------------------
export enum Library {
  Light = 'light',
  Regular = 'regular',
  Solid = 'solid',
  Duotone = 'duotone',
  Brands = 'brands'
}
// -------------------------------------------------------------------------------------------------

// export function validateIconName(icon: string) {
//   return /^[a-z]+(?:-[a-z]+)*$/.test(icon);
// }

/**
 * Get font-awesome library
 */
export function getFaLibrary(library?: Library) {
  switch (library) {
    case Library.Light:
      return 'fal';
    case Library.Solid:
      return 'fas';
    case Library.Duotone:
      return 'fad';
    case Library.Brands:
      return 'fab';
    default:
      return 'far';
  }
}

/**
 * Use an icon
 */
export function useIcon(icon: Ref<string>, library?: Ref<Library>) {
  // Validate icon name
  // if (!validateIconName(icon.value)) throw new Error('Wrong icon format');
  // Create class
  const iconClass = computed(() => {
    return `${getFaLibrary(library?.value)} fa-${icon.value} fa-fw`;
  });

  return {
    iconClass
  };
}
