// Import vendors ----------------------------------------------------------------------------------
import Vue from 'vue';
import VueRouter from 'vue-router';
import { distinctUntilKeyChanged, pairwise, skipWhile, take } from 'rxjs/operators';
import { capitalize, pick } from 'lodash';
import jwt from 'jsonwebtoken';
// Import plugins ----------------------------------------------------------------------------------
import { Podocore, container, TOKENS } from '@/plugins/podocore';
import i18n from './i18n';
// Import utils ------------------------------------------------------------------------------------
import { Layout } from '@/utils/layouts.utils';
import { useAnalytics } from '@/utils/analytics.utils';
// Import types ------------------------------------------------------------------------------------
import type { NavigationGuardNext, Route, RouteConfig, RouteMeta } from 'vue-router';
import type { Subscription } from 'rxjs';
import {
  AbilityAction,
  AbilitySubject
} from '@digitsole/blackburn-entities/dist/entities/ability/types/ability.enums';
// Export / declare types --------------------------------------------------------------------------
declare module 'vue-router' {
  interface RouteMeta {
    layout?: Layout;
    breadcrumb?(): string;
  }
}
// -------------------------------------------------------------------------------------------------

Vue.use(VueRouter);

// Flag to indicate if the route have successfully completed the initial navigation
let isReady = false;

// Amplitude
const { trackSuccess } = useAnalytics();

const routes: Array<RouteConfig & { meta: RouteMeta }> = [
  {
    path: '/',
    name: 'home',
    component: async () => import(/* webpackChunkName: "home" */ '../pages/PageHome.vue'),
    meta: {
      layout: Layout.Default,
      breadcrumb: () => capitalize(i18n.t('commons.standards.home').toString())
    }
  },
  {
    path: '/patients',
    name: 'patients',
    component: async () => import(/* webpackChunkName: "patients" */ '../pages/patient/PagePatients.vue'),
    meta: {
      layout: Layout.Default,
      breadcrumb: () => capitalize(i18n.tc('commons.standards.patient', 2).toString())
    },
    children: [
      {
        path: ':cuid',
        name: 'patient',
        component: async () => import(/* webpackChunkName: "patient" */ '../pages/patient/PagePatient.vue'),
        meta: {
          layout: Layout.Default,
          breadcrumb: () => capitalize(i18n.tc('commons.standards.patient', 1).toString())
        },
        children: [
          {
            path: 'comparison',
            name: 'patient-comparison',
            component: async () =>
              import(
                /* webpackChunkName: "patient-comparison" */ '../pages/patient/PagePatientResultsComparison.vue'
              ),
            meta: {
              breadcrumb: () => capitalize(i18n.t('commons.standards.comparison').toString())
            }
          },
          {
            path: 'result/:analysisCuid',
            name: 'patient-result',
            component: async () =>
              import(/* webpackChunkName: "patient-result" */ '../pages/patient/PagePatientResults.vue'),
            meta: {
              breadcrumb: () => capitalize(i18n.tc('commons.standards.analysis', 1).toString())
            }
          }
        ]
      }
    ]
  },
  {
    path: '/settings',
    name: 'settings',
    component: async () => import(/* webpackChunkName: "settings" */ '../pages/PageSettings.vue'),
    meta: {
      layout: Layout.Default,
      breadcrumb: () => capitalize(i18n.tc('commons.standards.setting', 2).toString())
    }
  },
  {
    path: '/equipment',
    name: 'equipment',
    component: async () => import(/* webpackChunkName: "equipment" */ '../pages/PageEquipment.vue'),
    meta: {
      layout: Layout.Default,
      breadcrumb: () => capitalize(i18n.tc('commons.standards.equipment', 2).toString())
    }
  },
  {
    path: '/account',
    name: 'account',
    component: async () => import(/* webpackChunkName: "account" */ '../pages/PageAccount.vue'),
    meta: {
      layout: Layout.Default,
      breadcrumb: () => capitalize(i18n.t('commons.standards.account').toString())
    }
  },
  {
    path: '/saas',
    name: 'saas',
    component: async () => import(/* webpackChunkName: "saas" */ '../pages/PageSaas.vue'),
    meta: {
      layout: Layout.Default,
      breadcrumb: () => capitalize(i18n.t('saas.page.title').toString())
    }
  },
  {
    path: '/auth/sign-in',
    name: 'auth--sign-in',
    component: async () => import(/* webpackChunkName: "auth--sign-in" */ '../pages/auth/PageAuthSignIn.vue'),
    meta: {
      layout: Layout.Auth
    }
  },
  {
    path: '/auth/sign-up',
    name: 'auth--sign-up',
    component: async () => import(/* webpackChunkName: "auth--sign-up" */ '../pages/auth/PageAuthSignUp.vue'),
    meta: {
      layout: Layout.Auth
    }
  },
  {
    path: '/auth/create-profile',
    name: 'auth--create-profile',
    component: async () =>
      import(/* webpackChunkName: "auth--create-profile" */ '../pages/auth/PageAuthCreateProfile.vue'),
    meta: {
      layout: Layout.Auth
    }
  },
  {
    path: '/auth/complete-profile',
    name: 'auth--complete-profile',
    component: async () =>
      import(/* webpackChunkName: "auth--complete-profile" */ '../pages/auth/PageAuthCompleteProfile.vue'),
    meta: {
      layout: Layout.Auth
    }
  },
  {
    path: '/auth/reset-password',
    name: 'auth--reset-password',
    component: async () =>
      import(/* webpackChunkName: "auth--reset-password" */ '../pages/auth/PageAuthResetPassword.vue'),
    meta: {
      layout: Layout.Auth
    }
  },
  {
    path: '/join-workspace',
    name: 'join-workspace',
    component: async () => import(/* webpackChunkName: "not-found" */ '../pages/PageJoinWorkspace.vue'),
    meta: {
      layout: Layout.Unknown
    }
  },
  {
    path: '*',
    name: 'not-found',
    component: async () => import(/* webpackChunkName: "not-found" */ '../pages/PageNotFound.vue'),
    meta: {
      layout: Layout.Unknown
    }
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: async () => import(/* webpackChunkName: "pricing" */ '../pages/PagePricing.vue'),
    meta: {
      layout: Layout.Default,
      breadcrumb: () => capitalize('pricing')
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const podocore = container.get<Podocore>(TOKENS.Podocore);
  document.title = to.name ? i18n.t(`commons.router.${to.name}`).toString() : 'Digitsole PRO';
  // Save query for SaaSsaas_plan_id
  /* if (to.name && ['home', 'auth--sign-up'].includes(to.name)) {
    const sQuery: { planId?: string; workspaceCuid?: string; doctorCuid?: string } = {};
    if (typeof to.query.saas_plan_id === 'string' && to.query.saas_plan_id.length) {
      sQuery['planId'] = to.query.saas_plan_id;
      sQuery['workspaceCuid'] = to.query.workspaceCuid as string | undefined;
      sQuery['doctorCuid'] = to.query.doctorCuid as string | undefined;
      sessionStorage.setItem('saas', JSON.stringify(sQuery));
      return next({ name: to.name, query: omit(to.query, ['saas_plan_id', 'workspaceCuid', 'doctorCuid']) });
    }
  } */

  // Workspace join
  if (to.name && typeof to.query.join_workspace_token === 'string' && to.query.join_workspace_token.length) {
    console.log('🔧 Join workspace token detected');

    const decodedToken = jwt.decode(to.query.join_workspace_token) as jwt.JwtPayload | null;

    if (!decodedToken) throw new Error('Join workspace token cannot be decoded');

    const sQuery: {
      doctorCuid?: string;
      env?: string;
      workspaceCuid?: string;
      token?: string;
    } = {};

    sQuery['doctorCuid'] = decodedToken.doctorCuid;
    sQuery['env'] = decodedToken.env;
    sQuery['workspaceCuid'] = decodedToken.workspaceCuid;
    sQuery['token'] = to.query.join_workspace_token;

    sessionStorage.setItem('join_workspace', JSON.stringify(sQuery));

    console.log('🔧 Join workspace token saved');
  }

  // Sass license
  if (to.name && typeof to.query.saas_license === 'string' && to.query.saas_license.length) {
    console.log('🛒 SaaS license detected');

    sessionStorage.setItem('saas_license', to.query.saas_license);

    console.log('🛒 SaaS license saved');
  }

  // SaaS token processing
  if (to.name && typeof to.query.saas_token === 'string' && to.query.saas_token.length) {
    console.log('🛒 SaaS token detected');

    const decodedToken = jwt.decode(to.query.saas_token) as jwt.JwtPayload | null;

    if (!decodedToken) throw new Error('SaaS token cannot be decoded');

    const sQuery: {
      planId?: string;
      workspaceCuid?: string;
      cognitoId?: string;
      addons?: string;
      couponIds?: string;
    } = {};

    sQuery['planId'] = decodedToken.data.planId;
    sQuery['addons'] = decodedToken.data.addons;
    sQuery['couponIds'] = decodedToken.data.couponIds;
    sQuery['workspaceCuid'] = decodedToken.data.workspaceCuid;
    sQuery['cognitoId'] = decodedToken.data.cognitoId;

    sessionStorage.setItem('saas', JSON.stringify(sQuery));

    console.log('🛒 SaaS token saved');
  }

  // If router is ready, follow conditions below
  if (isReady) {
    redirect(to, from, next);

    const bus = podocore.getModule('bus');
    bus.publish(bus.events.routeLeave({ to, from }));
  } else {
    // Waiting core to be initialized

    console.log('⏳ Router waiting core...');

    podocore.state$
      .pipe(
        skipWhile((state) => state.matches('bootstrap')),
        take(1)
      )
      .subscribe({
        next(state) {
          if (state.matches('ready')) {
            console.log('✅ Router detected core initialization');

            // Up flag
            isReady = true;

            // Start events listeners
            startEventListeners();

            // Start state watcher
            startStateWatcher();

            // Process join workspace storage
            const joinWorkspace = sessionStorage.getItem('join_workspace');
            if (joinWorkspace) {
              const decodedSaas = JSON.parse(joinWorkspace);

              console.log('🔧 Join workspace token detected in storage');

              return next({ name: 'join-workspace', params: decodedSaas });
            }

            // Process SaaS storage
            const saas = sessionStorage.getItem('saas');
            if (saas) {
              const decodedSaas = JSON.parse(saas);

              console.log('🛒 SaaS token detected in storage');

              const authService = podocore.getModuleService('auth');

              if (
                authService.state.matches('authenticated') &&
                authService.state.context.user.attributes.sub !== decodedSaas.cognitoId
              ) {
                console.log('🛒 Forcing sign out');
                return authService.send({ type: 'SIGN_OUT' });
              }
            }

            /* if (
              to.name &&
              ['auth--sign-up', 'auth--sign-in'].includes(to.name) &&
              typeof to.query.saas_token === 'string' &&
              to.query.saas_token.length
            ) {
              console.log('🛒 SaaS token detected');

              const authService = podocore.getModuleService('auth');

              try {
                const decodedToken = jwt.decode(to.query.saas_token) as jwt.JwtPayload | null;

                if (!decodedToken) throw new Error('SaaS token is corrupted or expired');

                const sQuery: { planId?: string; workspaceCuid?: string; cognitoId?: string } = {};

                sQuery['planId'] = decodedToken.data.planId;
                sQuery['workspaceCuid'] = decodedToken.data.workspaceCuid;
                sQuery['cognitoId'] = decodedToken.data.cognitoId;

                sessionStorage.setItem('saas', JSON.stringify(sQuery));

                console.log('🛒 SaaS token saved');

                if (
                  authService.state.matches('authenticated') &&
                  authService.state.context.user.attributes.sub !== decodedToken.data.cognitoId
                ) {
                  console.log('🛒 Forcing sign out');
                  authService.send({ type: 'SIGN_OUT' });
                } else {
                  console.log('🛒 Bypass to sign in');
                  next({
                    name: to.name,
                    query: omit(to.query, ['saas_token'])
                  });
                }
              } catch (error) {
                console.error(error);

                console.log('🛒 Error detected, forcing sign out');
                authService.send({ type: 'SIGN_OUT' });
              }
            } */
            // Check rights to access pages
            const acl = podocore.getModule('acl');
            if (to.path.includes('/patients') && !acl.build.can(AbilityAction.Read, AbilitySubject.Patient))
              next({ name: 'home' });
            if (to.path.includes('/saas') && !acl.build.can(AbilityAction.Update, AbilitySubject.Saas))
              next({ name: 'home' });
            // Analyse current states
            const doctor = podocore.getModule('doctor');

            if (doctor?.service?.state.matches('creating')) {
              // Bypass redirect
              next({ name: 'auth--create-profile' });
            } else if (doctor?.service?.state.matches('completing')) {
              // Bypass redirect
              next({ name: 'auth--complete-profile' });
            } else {
              // Run redirect
              redirect(to, from, next);
            }
          } else {
            console.log('🛑 Router detected core failure');
          }
        }
      });
  }
});

router.afterEach((to, from) => {
  const podocore = container.get<Podocore>(TOKENS.Podocore);
  const bus = podocore.getModule('bus');
  bus.publish(bus.events.routeEnter({ to, from }));

  if (from.name?.startsWith('auth') && !to.name?.startsWith('auth')) {
    // Erase SaaS license
    sessionStorage.removeItem('saas_license');
    console.log('🛒 SaaS license erased');
  }

  // Analytics
  if (to.name) trackSuccess(`Go to ${to.name}`);
});

function redirect(
  to: Pick<Route, 'name'> & Partial<Pick<Route, 'query'>>,
  from: Pick<Route, 'name'>,
  next: NavigationGuardNext
): void {
  const authService = container.get<Podocore>(TOKENS.Podocore).getModuleService('auth');

  if (from.name && to.name) {
    console.log(`🛫 Leave "${from.name}" and go to "${to.name}"`);
  }

  // Bypass if sign out
  if (to.name === 'auth--sign-out') {
    next();
    return;
  }

  // Signed out, redirect to sign in
  if (
    authService.state.matches('unauthenticated') &&
    (!String(to.name).startsWith('auth--') ||
      ['auth--create-profile', 'auth--complete-profile'].includes(String(to.name)))
  ) {
    sessionStorage.setItem('redirect_to', JSON.stringify(pick(to, ['name', 'query', 'params'])));
    next({ name: 'auth--sign-in' });
    return;
  }

  // Signed in, redirect to home
  if (authService.state.matches('authenticated') && String(to.name).startsWith('auth--')) {
    const doctorService = container.get<Podocore>(TOKENS.Podocore).getModuleService('doctor');

    if (String(to.name) === 'auth--create-profile' && doctorService.state.matches({ creating: 'idle' })) {
      next();
      return;
    } else if (
      String(to.name) === 'auth--complete-profile' &&
      doctorService.state.matches({ completing: 'idle' })
    ) {
      next();
      return;
    }

    const redirectTo = sessionStorage.getItem('redirect_to');

    if (redirectTo) {
      next(redirectTo);
      sessionStorage.removeItem('redirect_to');
    } else {
      next({ name: 'home' });
    }

    return;
  }

  next();
}

function startEventListeners() {
  console.log('👂 Router listen events');

  const bus = container.get<Podocore>(TOKENS.Podocore).getModule('bus');

  bus.subscribe(bus.events.routeBypass, async ({ payload }) => {
    if (router.currentRoute.name && router.currentRoute.name !== payload.name) {
      console.log(`⏩ Force redirection to "${payload.name}"`);
      await router.push({ name: payload.name });
    }
  });
}

function startStateWatcher() {
  console.log('🔎 Router watching states');

  const core = container.get<Podocore>(TOKENS.Podocore);

  const authModule = core.getModule('auth');

  let doctorModuleSubscription: Subscription | undefined;
  let workspaceModuleSubscription: Subscription | undefined;

  authModule.state$
    .pipe(distinctUntilKeyChanged('value'), pairwise())
    .subscribe(async ([previousState, currentState]) => {
      if (currentState.matches('authenticated')) {
        if (!doctorModuleSubscription) {
          // Listen doctor module state
          doctorModuleSubscription = core
            .getModule('doctor')
            .state$.pipe(distinctUntilKeyChanged('value'), pairwise())
            .subscribe(async ([previousDoctorState, currentDoctorState]) => {
              if (
                (previousDoctorState.matches({ creating: 'processing' }) ||
                  previousDoctorState.matches({ completing: 'processing' })) &&
                currentDoctorState.matches('success')
              ) {
                // Activate workspaces module
                core.getModuleService('workspaces', true).send({ type: 'ENABLE' });

                // Listen workspaces module
                if (!workspaceModuleSubscription) {
                  workspaceModuleSubscription = core
                    .getModule('workspaces')
                    .state$.pipe(distinctUntilKeyChanged('value'), pairwise())
                    .subscribe(async ([previousWorkspacesState, currentWorkspacesState]) => {
                      if (
                        previousWorkspacesState.matches('selecting') &&
                        currentWorkspacesState.matches('success')
                      ) {
                        await router.push({ name: 'home' });
                      }
                    });
                }
              }
            });
        }
      }

      // Sign out
      if (previousState.matches('authenticated') && currentState.matches('unauthenticated')) {
        console.log("✈ Redirecting to 'sign in'");
        await router.push({ name: 'auth--sign-in' });

        if (doctorModuleSubscription) doctorModuleSubscription.unsubscribe();
        if (workspaceModuleSubscription) workspaceModuleSubscription.unsubscribe();
      }
      // Sign in
      else if (
        (previousState.matches('fetching') || previousState.matches('saas')) &&
        currentState.matches('authenticated')
      ) {
        console.log("✈ Redirecting to 'home'");
        const redirectTo = sessionStorage.getItem('redirect_to');

        if (redirectTo) {
          await router.push(JSON.parse(redirectTo));
          sessionStorage.removeItem('redirect_to');
        } else {
          await router.push({ name: 'home' });
        }
      }
    });
}

export default router;
