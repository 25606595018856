export const TOKENS = {
  // =====

  Podocore: Symbol.for('Podocore'),

  // =====

  Modules: Symbol.for('Modules'),

  AclModule: Symbol.for('AclModule'),
  AuthModule: Symbol.for('AuthModule'),
  BusModule: Symbol.for('BusModule'),
  ProfileModule: Symbol.for('ProfileModule'),
  DoctorModule: Symbol.for('DoctorModule'),
  MaintenanceModule: Symbol.for('MaintenanceModule'),
  RequestModule: Symbol.for('RequestModule'),
  WebsocketModule: Symbol.for('WebsocketModule'),
  WorkspacesModules: Symbol.for('WorkspacesModule'),
  VersionModule: Symbol.for('VersionModule'),
  SaasModule: Symbol.for('SaasModule'),

  // =====

  Repositories: Symbol.for('Repositories'),

  MaintenanceRepository: Symbol.for('MaintenanceRepository'),
  DoctorRepository: Symbol.for('DoctorRepository'),
  WorkspacesRepository: Symbol.for('WorkspacesRepository'),
  SaasRepository: Symbol.for('SaasRepository')
};
