// Import vendors ----------------------------------------------------------------------------------
import bcp47Normalize from 'bcp-47-normalize';
import { usePreferredLanguages } from '@vueuse/core';
import { computed } from '@vue/composition-api';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { groupBy } from 'lodash';
// Import assets -----------------------------------------------------------------------------------
import countries, { ICountry } from '@digitsole/blackburn-iso-3166-dial';
// Import utils ------------------------------------------------------------------------------------
import { usePodocoreModuleService } from '@/plugins/podocore';
// Export / declare types --------------------------------------------------------------------------
export enum SupportedLang {
  En = 'en',
  Fr = 'fr',
  Bg = 'bg',
  Pl = 'pl',
  Da = 'da',
  No = 'no',
  // eslint-disable-next-line unicorn/prevent-abbreviations
  El = 'el',
  Es = 'es' /* ,
  De = 'de',
  It = 'it',
  Cz = 'cz',
  Kr = 'kr',
  Hu = 'hu',
  Pt = 'pt',
  Es = 'es',
  Fi = 'fi' */
}
// -------------------------------------------------------------------------------------------------

/**
 * Normalize lang format to ISO 639-1
 */
export function normalizeLang(lang: string, fallback = SupportedLang.En): SupportedLang {
  let n = bcp47Normalize(lang);

  n = n.split('-')[0];

  return n in SupportedLang ? (n as SupportedLang) : fallback;
}

/**
 * Get normalized language from the navigator (fallback if supported lang)
 */
export function navigatorLang() {
  return normalizeLang(navigator.language);
}

export function useI18n() {
  const preferredLanguage = usePreferredLanguages();

  const doctorService = usePodocoreModuleService('doctor');

  const navigatorLang = computed(() => {
    return normalizeLang(preferredLanguage.value[0]);
  });

  const currentLang = computed<SupportedLang>(() => {
    return (doctorService?.state.value.context as any).doctor?.config.lang ?? navigatorLang.value;
  });

  const dateFormat = computed(() => {
    switch (currentLang.value) {
      case 'fr':
        return 'DD/MM/YYYY';
      case 'pl':
        return 'DD/MM/YYYY';
      default:
        return 'MM/DD/YYYY';
    }
  });

  const hourFormat = computed(() => {
    switch (currentLang.value) {
      case 'fr':
        return 'HH:mm';
      default:
        return 'hh:mm A';
    }
  });

  return {
    navigatorLang,
    currentLang,
    dateFormat,
    hourFormat
  };
}

export function getCountries(): ICountry[] {
  //: ICountry[]
  const supportedRegions = PhoneNumberUtil.getInstance().getSupportedRegions();
  return countries.onlyWithDial().filter((country: any) => supportedRegions.includes(country.alpha2));
}

export function getGroupedByDialCountries(): { dial: string; countries: ICountry[] }[] {
  return Object.entries(groupBy(getCountries(), 'dial')).map(([dial, countries]) => ({
    dial,
    countries
  }));
}
