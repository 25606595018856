// Import vendors ----------------------------------------------------------------------------------
import { injectable } from 'inversify';
// Import factories --------------------------------------------------------------------------------
import { RepositoryFactory } from '../factories/Repository.factory';
// Import types ------------------------------------------------------------------------------------
import type { Entity, Phone } from '../helpers/repositories.helper';
// -------------------------------------------------------------------------------------------------

export enum EUnit {
  Metric = 'metric',
  Imperial = 'imperial'
}

export enum DoctorProfession {
  Neurologist = 'neurologist',
  OrthopaedicSurgeon = 'orthopaedic_surgeon',
  Osteopath = 'osteopath',
  Physiotherapist = 'physiotherapist',
  Podiatrist = 'podiatrist',
  SportsCoach = 'sports_coach'
}

export interface DoctorProfile {
  firstName: string;
  lastName: string;
  professions: DoctorProfession[];
}

export interface DoctorConfig {
  lang: string;
}

export interface DoctorNewsletters {
  consented: boolean;
}

export interface Doctor {
  userCuid: string;
  config: DoctorConfig;
  newsletters: DoctorNewsletters;
  phone?: Phone;
  preferredOfficeCuid?: string;
}

/**
 * Doctor repository
 */
@injectable()
export class DoctorRepository extends RepositoryFactory {
  async fetchDoctor(): Promise<Entity<Doctor>> {
    return Promise.resolve() as Promise<any>;
  }
}
