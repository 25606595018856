// Import vendors ----------------------------------------------------------------------------------
import { injectable } from 'inversify';
import { merge } from 'lodash';
// Import factories --------------------------------------------------------------------------------
import { RepositoryFactory } from '../factories/Repository.factory';
// Import configurations ---------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
import { saasConfig } from '@/config/saas.config';
// Import types ------------------------------------------------------------------------------------
import type { Observable } from 'rxjs';
import type { GetObservableGeneric } from '@/utils/types.utils';
import type { AxiosResponse, AxiosRequestConfig } from 'axios';
import type { WorkspaceEntity } from '@digitsole/blackburn-entities/dist/entities/workspace/workspace.entity';
import type { WorkspacesController } from '@digitsole/blackburn-api/dist/routes/workspaces/workspaces.controller';
import type { UpdateWorkspaceDto } from '@digitsole/blackburn-entities/dist/entities/workspace/dto/update-workspace.dto';
// Export types ------------------------------------------------------------------------------------
export type { WorkspaceEntity } from '@digitsole/blackburn-entities/dist/entities/workspace/workspace.entity';
// -------------------------------------------------------------------------------------------------

/**
 * Workspaces repository
 */
@injectable()
export class WorkspacesRepository extends RepositoryFactory {
  /**
   * Fetch SaaS meta
   */
  fetchSaasMeta(config?: AxiosRequestConfig): Observable<AxiosResponse> {
    return this._requestModule.authenticatedRequest(`${saasConfig.default}/meta`, config);
  }

  /**
   * Fetch workspaces
   */
  fetchWorkspaces(
    config?: AxiosRequestConfig
  ): Observable<AxiosResponse<GetObservableGeneric<ReturnType<WorkspacesController['readAll']>>>> {
    return this._requestModule.authenticatedRequest<
      GetObservableGeneric<ReturnType<WorkspacesController['readAll']>>
    >(`${apiConfig.default}/workspaces`, config);
  }

  /**
   * Create workspaces
   */
  createWorkspace(
    // createWorkspaceDto: CreateWorkspaceDto,
    config?: AxiosRequestConfig
  ): Observable<AxiosResponse<GetObservableGeneric<ReturnType<WorkspacesController['create']>>>> {
    return this._requestModule.authenticatedRequest<
      GetObservableGeneric<ReturnType<WorkspacesController['create']>>
    >(
      `${apiConfig.default}/workspaces`,
      merge({}, config, {
        method: 'POST'
        // data: createWorkspaceDto
      })
    );
  }

  /**
   * Patch workspaces
   */
  patchWorkspace(
    workspaceCuid: WorkspaceEntity['cuid'],
    updateWorkspaceDto: UpdateWorkspaceDto,
    config?: AxiosRequestConfig
  ): Observable<AxiosResponse<GetObservableGeneric<ReturnType<WorkspacesController['update']>>>> {
    return this._requestModule.authenticatedRequest<
      GetObservableGeneric<ReturnType<WorkspacesController['update']>>
    >(
      `${apiConfig.default}/workspaces/${workspaceCuid}`,
      merge({}, config, {
        method: 'PATCH',
        data: updateWorkspaceDto
      })
    );
  }
}
