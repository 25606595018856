// Import vendors ----------------------------------------------------------------------------------
import { injectable } from 'inversify';
import { actions, assign, sendParent } from 'xstate';
import { from, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { merge } from 'lodash';
import moment from 'moment';
import { datadogRum } from '@datadog/browser-rum';
// Import factories --------------------------------------------------------------------------------
import { ModuleWithStateFactory } from '@/plugins/podocore/factories/ModuleWithState.factory';
// Import plugins ----------------------------------------------------------------------------------
import i18n from '@/plugins/i18n';
// Import helpers ----------------------------------------------------------------------------------
import { createModuleStateMachine } from '@/plugins/podocore/helpers/modules.helpers';
// Import configurations ---------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// Import types ------------------------------------------------------------------------------------
import type { SupportedLang } from '@/utils/i18n.utils';
import type {
  DoctorEntityConfig,
  DoctorEntityNewslettersAcceptance,
  DoctorEntityProfile
} from '@digitsole/blackburn-entities/dist/entities/doctor/doctor.entity';
import { EUnit } from '../../repositories/doctor.repository';
import { DoctorEntityStatus } from '@digitsole/blackburn-entities/dist/entities/doctor/types/doctor.enums';
// Export / declare types --------------------------------------------------------------------------
export enum Professions {
  Neurologist = 'neurologist',
  OrthopaedicSurgeon = 'orthopaedic_surgeon',
  Osteopath = 'osteopath',
  Physiotherapist = 'physiotherapist',
  Podiatrist = 'podiatrist',
  SportsCoach = 'sports_coach'
}

type DoctorProfile = {
  fistName: string;
  lastName: string;
  professions: Professions[];
};

type DoctorConfig = {
  lang: SupportedLang;
  unit?: EUnit;
};

type Doctor = DoctorProfile & {
  cuid: string;
  profile: DoctorProfile;
  config: DoctorConfig;
  preferredOfficeCuid?: string;
  newsletters?: {
    consented: boolean;
  };
  consents: any[];
  status: DoctorEntityStatus;
};
// -------------------------------------------------------------------------------------------------

/**
 * Doctor module
 */
@injectable()
export class DoctorModule extends ModuleWithStateFactory {
  constructor() {
    super();

    const unsubscribeSet = new Set<() => void>();

    const commonsEvents = {
      CHECK: '#module.fetching',
      PATCH_PROFILE: '#module.patching.profile',
      PATCH_CONFIG: '#module.patching.config',
      PATCH_PREFERRED_OFFICE: '#module.patching.preferredOffice',
      CONSENT: '#module.patching.consent'
    };

    this._machine = createModuleStateMachine(
      this._name,
      {
        initial: 'fetching',
        context: {
          doctor: undefined,
          error: undefined,
          creatingError: undefined,
          completingError: undefined,
          patchingProfileError: undefined,
          patchingConfigError: undefined,
          patchingPreferredOfficeError: undefined,
          consentError: undefined
        },
        states: {
          fetching: {
            invoke: {
              src: 'fetchDoctor',
              onDone: {
                actions: [
                  assign({
                    doctor: (_, { data }) => {
                      return this.doctorMiddleware(data);
                    }
                  }),
                  function () {
                    console.log('💊 Doctor updated');
                  }
                ],
                target: 'success'
              },
              onError: [
                {
                  cond(_, { data }) {
                    return data.response?.status === 404;
                  },
                  target: 'creating'
                },
                {
                  cond(_, { data }) {
                    return data.response?.status === 422;
                  },
                  actions: assign({
                    doctor: (_, { data }) => {
                      return this.doctorMiddleware(data.response.data.doctor);
                    }
                  }),
                  target: 'completing'
                },
                {
                  actions: assign({
                    error(_, { data }) {
                      return data;
                    }
                  }),
                  target: 'failure'
                }
              ]
            }
          },
          creating: {
            entry: [
              sendParent({ type: 'NOT_EXIST' }),
              () => {
                console.log('🆔 Waiting doctor creation');

                const bus = this._core.getModule('bus');
                bus.publish(bus.events.routeBypass({ name: 'auth--create-profile' }));
              }
            ],
            initial: 'idle',
            states: {
              idle: {
                on: { ...commonsEvents, CREATE: '#module.creating.processing' }
              },
              processing: {
                invoke: {
                  src: 'createDoctor',
                  onDone: {
                    actions: assign({
                      doctor: (_, { data }) => {
                        return this.doctorMiddleware(data);
                      }
                    }),
                    target: '#module.success'
                  },
                  onError: {
                    actions: assign({
                      creatingError(_, { data }) {
                        return data;
                      }
                    }),
                    target: 'failure'
                  }
                }
              },
              failure: {
                exit: assign({
                  creatingError(_) {
                    return undefined;
                  }
                }),
                on: { ...commonsEvents, CREATE: '#module.creating.processing' }
              }
            }
          },
          completing: {
            entry: [
              sendParent({ type: 'NOT_COMPLETE' }),
              () => {
                console.log('🆔 Waiting doctor completion');

                const bus = this._core.getModule('bus');
                bus.publish(bus.events.routeBypass({ name: 'auth--complete-profile' }));
              }
            ],
            initial: 'idle',
            states: {
              idle: {
                on: { ...commonsEvents, COMPLETE: '#module.completing.processing' }
              },
              processing: {
                invoke: {
                  src: 'completeDoctor',
                  onDone: {
                    actions: assign({
                      doctor: (_, { data }) => {
                        return this.doctorMiddleware(data);
                      }
                    }),
                    target: '#module.success'
                  },
                  onError: {
                    actions: assign({
                      creatingError(_, { data }) {
                        return data;
                      }
                    }),
                    target: 'failure'
                  }
                }
              },
              failure: {
                exit: assign({
                  completingError(_) {
                    return undefined;
                  }
                }),
                on: { ...commonsEvents, CREATE: '#module.completing.processing' }
              }
            }
          },
          patching: {
            states: {
              profile: {
                initial: 'processing',
                states: {
                  processing: {
                    invoke: {
                      src: 'patchDoctorProfile',
                      onDone: {
                        actions: assign({
                          doctor: (context, { data }) => {
                            return this.doctorMiddleware({
                              ...context.doctor,
                              profile: data.profile
                            });
                          }
                        }),
                        target: 'success'
                      },
                      onError: {
                        actions: assign({
                          patchingProfileError(_, { data }) {
                            return data;
                          }
                        }),
                        target: 'failure'
                      }
                    }
                  },
                  success: {
                    on: commonsEvents,
                    after: {
                      3000: '#module.success'
                    }
                  },
                  failure: {
                    exit: assign({
                      patchingProfileError(_) {
                        return undefined;
                      }
                    }),
                    on: commonsEvents
                  }
                }
              },
              config: {
                initial: 'processing',
                states: {
                  processing: {
                    invoke: {
                      src: 'patchDoctorConfig',
                      onDone: {
                        actions: assign({
                          doctor: (context, { data }) => {
                            return this.doctorMiddleware({
                              ...context.doctor,
                              config: data.config
                            });
                          }
                        }),
                        target: 'success'
                      },
                      onError: {
                        actions: assign({
                          patchingConfigError(_, { data }) {
                            return data;
                          }
                        }),
                        target: 'failure'
                      }
                    }
                  },
                  success: {
                    on: commonsEvents,
                    after: {
                      3000: '#module.success'
                    }
                  },
                  failure: {
                    exit: assign({
                      patchingConfigError(_) {
                        return undefined;
                      }
                    }),
                    on: commonsEvents
                  }
                }
              },
              preferredOffice: {
                initial: 'processing',
                states: {
                  processing: {
                    invoke: {
                      src: 'patchDoctorPreferredOffice',
                      onDone: {
                        actions: assign({
                          doctor: (context, { data }) => {
                            return this.doctorMiddleware({
                              ...context.doctor,
                              preferredOfficeCuid: data.preferredOfficeCuid
                            });
                          }
                        }),
                        target: 'success'
                      },
                      onError: {
                        actions: assign({
                          patchingPreferredOfficeError(_, { data }) {
                            return data;
                          }
                        }),
                        target: 'failure'
                      }
                    }
                  },
                  success: {
                    on: commonsEvents,
                    after: {
                      3000: '#module.success'
                    }
                  },
                  failure: {
                    exit: assign({
                      patchingPreferredOfficeError(_) {
                        return undefined;
                      }
                    }),
                    on: commonsEvents
                  }
                }
              },
              consent: {
                initial: 'processing',
                states: {
                  processing: {
                    invoke: {
                      src: 'consent',
                      onDone: {
                        actions: assign({
                          doctor: (context, { data }) => {
                            return this.doctorMiddleware({
                              ...context.doctor,
                              consents: data.consents,
                              _unconsentedDocuments: context.doctor._unconsentedDocuments.filter(
                                (_unconsentedDocument: any) =>
                                  !data.consents.find(
                                    (consent: any) => consent.cuid === _unconsentedDocument.cuid
                                  )
                              )
                            });
                          }
                        }),
                        target: 'success'
                      },
                      onError: {
                        actions: assign({
                          consentError(_, { data }) {
                            return data;
                          }
                        }),
                        target: 'failure'
                      }
                    }
                  },
                  success: {
                    always: '#module.fetching'
                  },
                  failure: {
                    exit: assign({
                      consentError(_) {
                        return undefined;
                      }
                    }),
                    on: commonsEvents
                  }
                }
              }
            }
          },
          success: {
            entry: [
              actions.pure(() => sendParent({ type: 'FETCHED' })),
              (context) => {
                const bus = this._core.getModule('bus');

                datadogRum.setUser({
                  doctorCuid: context.doctor.cuid
                });

                // Listen office creation
                if (!context.doctor.preferredOfficeCuid) {
                  unsubscribeSet.add(
                    bus.subscribe(bus.events.officeCreated, ({ payload }) => {
                      this.service.send({
                        type: 'PATCH_CONTEXT',
                        data: { preferredOfficeCuid: payload.officeCuid }
                      });
                    })
                  );
                }

                // Listen patient creation
                unsubscribeSet.add(
                  bus.subscribe(bus.events.patientCreated, () => {
                    this.service.send({
                      type: 'CHECK'
                    });
                  })
                );

                // Listen patient update
                unsubscribeSet.add(
                  bus.subscribe(bus.events.patientPatched, () => {
                    this.service.send({
                      type: 'CHECK'
                    });
                  })
                );

                // Listen patient deletion
                unsubscribeSet.add(
                  bus.subscribe(bus.events.patientDeleted, () => {
                    this.service.send({
                      type: 'CHECK'
                    });
                  })
                );

                // Listen workspace change
                unsubscribeSet.add(
                  bus.subscribe(bus.events.workspaceChanged, () => {
                    this.service.send({
                      type: 'CHECK'
                    });
                  })
                );
              }
            ],
            exit: () => {
              unsubscribeSet.forEach((unsubscribe) => unsubscribe());
              unsubscribeSet.clear();
            },
            on: {
              ...commonsEvents,
              PATCH_CONTEXT: {
                actions: assign({
                  doctor: (context, { data }) => {
                    return this.doctorMiddleware({
                      ...context.doctor,
                      ...data
                    });
                  }
                })
              }
            }
          },
          failure: {
            entry: actions.pure((context) => {
              return sendParent({ type: 'FATAL', data: context.error });
            }),
            exit: assign({
              error(_) {
                return undefined;
              }
            }),
            on: commonsEvents
          }
        }
      },
      {
        services: {
          fetchDoctor: this.fetchDoctor.bind(this),
          createDoctor: async (_, { data }) => this.createDoctor(data),
          completeDoctor: async (_, { data }) => this.completeDoctor(data),
          patchDoctorProfile: async (_, { data }) => this.patchDoctorProfile(data),
          patchDoctorConfig: async (_, { data }) => this.patchDoctorConfig(data),
          patchDoctorPreferredOffice: async (_, { data }) => this.patchDoctorPreferredOffice(data),
          consent: async (_, { data }) => this.consent(data)
        }
      }
    );
  }

  async fetchDoctor(): Promise<Doctor> {
    return this._core
      .getModule('request')
      .authenticatedRequest<Doctor>(`${apiConfig.default}/doctor`)
      .pipe(map((response) => response.data))
      .toPromise();
  }

  async createDoctor(profile: Partial<DoctorProfile>): Promise<Doctor> {
    return this._core
      .getModule('auth')
      .getUser()
      .pipe(
        map((user) => {
          const consents = localStorage.getItem(`consents:${user.attributes.email}`);

          if (consents) {
            localStorage.removeItem(`consents:${user.attributes.email}`);
            return JSON.parse(consents);
          } else {
            return [];
          }
        }),
        switchMap((consents) =>
          this._core
            .getModule('request')
            .authenticatedRequest<Doctor>(`${apiConfig.default}/doctor`, {
              method: 'POST',
              data: merge({}, profile, { consentsCuids: consents })
            })
            .pipe(map((response) => response.data))
        )
      )
      .toPromise();
  }

  async completeDoctor(doctor: Partial<Doctor>): Promise<Doctor> {
    const request = this._core.getModule('request');
    return of({})
      .pipe(
        switchMap(() =>
          request.authenticatedRequest<DoctorEntityConfig>(`${apiConfig.default}/doctor/config`, {
            method: 'PATCH',
            data: doctor.config
          })
        ),
        switchMap(() =>
          request.authenticatedRequest<DoctorEntityProfile>(`${apiConfig.default}/doctor/profile`, {
            method: 'PATCH',
            data: doctor.profile
          })
        ),
        switchMap(() =>
          request.authenticatedRequest<DoctorEntityNewslettersAcceptance>(
            `${apiConfig.default}/doctor/newsletters`,
            {
              method: 'PATCH',
              data: doctor.newsletters
            }
          )
        ),
        switchMap(() => from(this.fetchDoctor()))
      )
      .toPromise();
  }

  async patchDoctorProfile(profile: Partial<DoctorProfile>): Promise<Pick<Doctor, 'cuid' | 'profile'>> {
    return this._core
      .getModule('request')
      .authenticatedRequest<Doctor>(`${apiConfig.default}/doctor/profile`, {
        method: 'PATCH',
        data: profile
      })
      .pipe(map((response) => response.data))
      .toPromise();
  }

  async patchDoctorConfig(config: Partial<DoctorConfig>): Promise<Pick<Doctor, 'cuid' | 'config'>> {
    return this._core
      .getModule('request')
      .authenticatedRequest<Doctor>(`${apiConfig.default}/doctor/config`, {
        method: 'PATCH',
        data: config
      })
      .pipe(map((response) => response.data))
      .toPromise();
  }

  async patchDoctorPreferredOffice(
    preferredOffice: Pick<Doctor, 'preferredOfficeCuid'>
  ): Promise<Pick<Doctor, 'cuid' | 'preferredOfficeCuid'>> {
    return this._core
      .getModule('request')
      .authenticatedRequest<Doctor>(`${apiConfig.default}/doctor/set-preferred-office`, {
        method: 'PUT',
        data: preferredOffice
      })
      .pipe(map((response) => response.data))
      .toPromise();
  }

  async consent(payload: { documentCuid: string }): Promise<Pick<Doctor, 'cuid' | 'consents'>> {
    return this._core
      .getModule('request')
      .authenticatedRequest<Doctor>(`${apiConfig.default}/doctor/consent`, {
        method: 'POST',
        data: payload
      })
      .pipe(map((response) => response.data))
      .toPromise();
  }

  private doctorMiddleware(doctor: Doctor) {
    i18n.locale = doctor.config.lang;
    moment.locale(doctor.config.lang);

    return doctor;
  }
}
