"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AbilityFeature = exports.AbilityRule = exports.AbilitySubject = exports.AbilityAction = void 0;
var AbilityAction;
(function (AbilityAction) {
    AbilityAction["Create"] = "create";
    AbilityAction["Read"] = "read";
    AbilityAction["Update"] = "update";
    AbilityAction["Delete"] = "delete";
    AbilityAction["Invite"] = "invite";
    AbilityAction["Stop"] = "stop";
    AbilityAction["Cancel"] = "cancel";
    AbilityAction["Select"] = "select";
    AbilityAction["Use"] = "use";
})(AbilityAction = exports.AbilityAction || (exports.AbilityAction = {}));
var AbilitySubject;
(function (AbilitySubject) {
    AbilitySubject["Workspace"] = "workspace";
    AbilitySubject["Patient"] = "patient";
    AbilitySubject["Device"] = "device";
    AbilitySubject["Session"] = "session";
    AbilitySubject["Activity"] = "activity";
    AbilitySubject["Analysis"] = "analysis";
    AbilitySubject["Saas"] = "saas";
    AbilitySubject["Video"] = "video";
    AbilitySubject["Image"] = "image";
})(AbilitySubject = exports.AbilitySubject || (exports.AbilitySubject = {}));
var AbilityRule;
(function (AbilityRule) {
    AbilityRule[AbilityRule["CanCreateWorkspace"] = 0] = "CanCreateWorkspace";
    AbilityRule[AbilityRule["CanUpdateWorkspace"] = 1] = "CanUpdateWorkspace";
    AbilityRule[AbilityRule["CanDeleteWorkspace"] = 2] = "CanDeleteWorkspace";
    AbilityRule[AbilityRule["CanInviteToWorkspace"] = 3] = "CanInviteToWorkspace";
    AbilityRule[AbilityRule["CanCreateSession"] = 4] = "CanCreateSession";
    AbilityRule[AbilityRule["CanStopSession"] = 5] = "CanStopSession";
    AbilityRule[AbilityRule["CanCancelSession"] = 6] = "CanCancelSession";
    AbilityRule[AbilityRule["CanReadPatient"] = 7] = "CanReadPatient";
    AbilityRule[AbilityRule["CanCreatePatient"] = 8] = "CanCreatePatient";
    AbilityRule[AbilityRule["CanUpdatePatient"] = 9] = "CanUpdatePatient";
    AbilityRule[AbilityRule["CanDeletePatient"] = 10] = "CanDeletePatient";
    AbilityRule[AbilityRule["CanUseDevice"] = 11] = "CanUseDevice";
    AbilityRule[AbilityRule["CanCreateActivity"] = 12] = "CanCreateActivity";
    AbilityRule[AbilityRule["CanUpdateAnalysis"] = 13] = "CanUpdateAnalysis";
    AbilityRule[AbilityRule["CanDeleteAnalysis"] = 14] = "CanDeleteAnalysis";
    AbilityRule[AbilityRule["CanSubscribeToSaasPlan"] = 15] = "CanSubscribeToSaasPlan";
    AbilityRule[AbilityRule["CanCreateVideo"] = 16] = "CanCreateVideo";
    AbilityRule[AbilityRule["CanReadVideo"] = 17] = "CanReadVideo";
    AbilityRule[AbilityRule["CanUpdateVideo"] = 18] = "CanUpdateVideo";
    AbilityRule[AbilityRule["CanDeleteVideo"] = 19] = "CanDeleteVideo";
    AbilityRule[AbilityRule["CanCreateImage"] = 20] = "CanCreateImage";
    AbilityRule[AbilityRule["CanReadImage"] = 21] = "CanReadImage";
    AbilityRule[AbilityRule["CanUpdateImage"] = 22] = "CanUpdateImage";
    AbilityRule[AbilityRule["CanDeleteImage"] = 23] = "CanDeleteImage";
})(AbilityRule = exports.AbilityRule || (exports.AbilityRule = {}));
var AbilityFeature;
(function (AbilityFeature) {
    AbilityFeature["Walking"] = "walking";
    AbilityFeature["Running"] = "running";
    AbilityFeature["Rehab"] = "rehab";
    AbilityFeature["Video"] = "video";
})(AbilityFeature = exports.AbilityFeature || (exports.AbilityFeature = {}));
